<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >

    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog
      v-model="dialog.detail"
      fullscreen
      persistent
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''">Detail Pelanggan di Kategori Pelanggan
            <span class="font-weight-bold">({{ customer_category_category_name }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text dark fab @click="dialog.detail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''" style="height: 100vh">
          <div v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-simple-table>
              <template v-slot:default>
                <thead v-if="!process.run && detail.length > 0">
                  <tr>
                    <th style="width: 1%">Aksi</th>
                    <th style="width: 20%">Nama</th>
                    <th style="width: 10%" class="text-center">Jumlah Transaksi</th>
                    <th style="width: 10%" class="text-center">Diskon Outlet</th>
                    <th style="width: 13%" class="text-right">Total Pembelian (Rp)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detail" :key="index">
                    <td>
                      <v-btn
                        small
                        text
                        @click="toDetailTransaction(item)"
                        min-width="30"
                        class="text-capitalize orange--text text--darken-1 pa-0 mr-1 c-p">
                        <v-icon small>mdi-information-outline</v-icon>
                      </v-btn>
                    </td>
                    <td class="text-second">
                      {{ item.customer_name }}
                    </td>
                    <td class="text-second text-center">
                      {{ item.count | num_format }}
                    </td>
                    <td class="text-second text-center">
                      {{ item.discount_store_percent_label }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.grand_total_sum }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div v-if="$vuetify.breakpoint.name === 'xs'">
            <v-data-table
              :headers="headers_detail"
              :items="detail"
              :disable-sort="true"
              hide-default-footer
              :items-per-page="10000000000"
              class="elevation-1 hide_sort"
            >
              <template v-slot:[`item.customer_name`]="{ item }">
                {{ item.customer_name }}
              </template>
              <template v-slot:[`item.count`]="{ item }">
               {{ item.count | num_format }}
              </template>
              <template v-slot:[`item.discount_store_percent_label`]="{ item }">
               {{ item.discount_store_percent_label }}
              </template>
              <template v-slot:[`item.grand_total_sum`]="{ item }">
                {{ item.grand_total_sum }}
              </template>
            </v-data-table>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG TRANSAKSI   ==== -->
    <v-dialog
      v-model="dialog.detail_transaction"
      fullscreen
      persistent
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''">Detail Transaksi Pelanggan
            <span class="font-weight-bold">({{ customer_name }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text dark fab @click="dialog.detail_transaction = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''" style="height: 100vh">
          <base-table
              :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'calc(100vh - 200px)' : 'calc(100vh - 450px)'"
              v-show="displayBaseTableDetailTransaction"
              v-on:onRetrieveDataEnd="responseDataEndTransaction"
              v-on:onRetrieveDataStart="responseDataStartTransaction"
              v-on:onResetFilter="resetFilter"
              :isLoadAgain="isLoadAgains"
              :isExternalFilterButton="true"
              :isFilterOnHeader="true"
              ref="base-table"
              dateType="date"
              dateFormat="DD MMMM YYYY"
              itemKey="id"
              :single-select="singleSelect"
              :show-select="false"
              :headers="headers_detail_transaction"
              :retrieveDataURL="`franchise/report/sales-customer-category/customer/sales-order?customer_id=${customer_id}&store_id=${selected_store_id}`"
              filterType="laporan">
              <template v-slot:[`item.aksi_table`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="toDetailSalesOrder(item)"
                      v-on="on"
                      v-bind="attrs"
                      small
                      text
                      min-width="30"
                      class="text-capitalize orange--text text--darken-1 pa-0 mr-1 c-p"
                    >
                      <v-icon small>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Detail Transaksi</span>
                </v-tooltip>
              </template>

            </base-table>

            <!-- ==== COMPONENT DATA KOSONG ==== -->
            <Empty v-show="emptyData" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ===== DIALOG DETAIL TRANSAKSI ===== -->
    <v-dialog
      v-model="dialog.detailSalesResume"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark max-height="70" color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''"
            >Detail Penjualan
            <span class="font-weight-bold">({{ transaction_number }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark fab @click="dialog.detailSalesResume = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.name === 'xs' ? 'pt-1' : ''">
              <!-- ==== INFORMASI TRANSAKSI ==== -->
              <v-card flat>
                <div class="font-weight-regular text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Informasi Transaksi
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr v-for="(item, key) in cardDetailInfo.data_array" :key="key">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">{{ item.label }}</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ item.value }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <!-- ==== RINGKASAN TOTAL ==== -->
              <v-card flat>
                <div class="font-weight-regular text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Ringkasan Total
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr v-for="(item, key) in cardDetailSummary.data_array" :key="key">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">
                          {{ item.label }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                size="15"
                                class="ml-4"
                                color="#d31145"
                                v-on="on"
                                v-if="item.note.title !== ''"
                                v-bind="attrs">mdi-help-circle
                              </v-icon>
                            </template>
                            <div class="d-flex flex-column">
                              <span class="font-weight-bold">{{ item.note.title }}</span>
                              <span>{{ item.note.content }}</span>
                            </div>
                          </v-tooltip>
                        </th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ item.value }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <div class="font-weight-regular mt-7 text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-0' : 'text-h6 mb-5'">
            List Transaksi
          </div>
          <v-data-table
            flat
            class="laporan"
            :class="$vuetify.breakpoint.name === 'xs' ? 'mt-0' : 'mt-5'"
            :headers="headersDetail"
            :loading="loading"
            hide-default-footer
            disable-pagination
            :options.sync="options"
            :items="cardDetail.data_array"
          >
            <template v-slot:[`item.product_image_url`]="{ item }">
              <v-list-item class="pa-0">
                <v-list-item-avatar class="mr-3">
                  <v-img
                    :lazy-src="item.product_image_url ? item.product_image_url : 'https://cdn-icons-png.flaticon.com/512/4689/4689798.png'"
                    :src="item.product_image_url ? item.product_image_url : 'https://cdn-icons-png.flaticon.com/512/4689/4689798.png'">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height justify-center ma-0"
                        align="center"                    
                      >
                        <v-progress-circular indeterminate color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
              </v-list-item>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DATA LAPORAN PELANGGAN ==== -->
    <div class="my-5">
      <div class="text--secondary mb-3" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
        Data Penjualan Per Pelanggan
      </div>
      <div class="mb-5 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/sales-customer-category/export_excel?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Per Pelanggan'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/sales-customer-category/export_pdf?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Per Pelanggan'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.date || status.customercategory">
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate.start | dateFull }} - {{ filterdate.end | dateFull }}
        </v-chip>

        <v-chip
          v-if="status.customercategory"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('customer_category')"
        >
          Pelanggan: {{ filternamecustomercategory }}
        </v-chip>
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Per Pelanggan</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="6" sm="6" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div>
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA PENJUALAN PER PELANGGAN ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- ==== BASE TABLE ==== -->
      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesCustomer"
        itemKey="id"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/sales-customer-category?store_id=${selected_store_id}`"
        filterType="laporan"
      >
        <template v-slot:[`item.aksi_table`]="{ item }">
          <v-btn
            small
            text
            @click="toDetail(item)"
            min-width="30"
            class="text-capitalize orange--text text--darken-1 pa-0 mr-1 c-p"
          >
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.customer_category_title`]="{ item }">
          {{ item.customer_category_title }}
        </template>
        <template v-slot:[`item.discount_store_percent_label`]="{ item }">
          {{ item.discount_store_percent_label }}
        </template>
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count | num_format }}
        </template>
        <template v-slot:[`item.grand_total_sum`]="{ item }">
          {{ item.grand_total_sum }}
        </template>

        <template v-slot:filterTitle>
          Filter Penjualan Per Tipe Pelanggan
        </template>

        <template v-slot:customFilter>
          <VueCtkDateTimePicker
            v-model="filterdate"
            label="Pilih Tanggal"
            :range="true"
            noClearButton
            color="#d31145"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="todays"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
            ]"
          />

          <v-autocomplete
            class="mt-5"
            item-text="title"
            item-value="id"
            :items="customer_category"
            outlined
            clearable
            dense
            color="#d31145"
            label="Kategori Pelanggan"
            v-model="filtercustomercategory"
          >
          </v-autocomplete>
        </template>
      </base-table>
      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";
// import Breadcrumbs from "@/components/Breadcrumbs";
export default {
  data() {
    return {
      panel: "",
      todays: new Date().toISOString().substr(0, 10),
      customer_category_category_name: "",
      customer_name: "",
      customer_id: "",
      transaction_number: "",
      /**
       * DATA COMPONENT BASETABLE
       */
      isLoadAgains: false,
      displayBaseTable: false,
      displayBaseTableDetailTransaction: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        { text: "Aksi", align: "center", width: 100, sortable: false, value: "aksi_table" },
        { text: "Kategori", align: "left", value: "customer_category_title" },
        { text: "Diskon", align: "left", value: "discount_store_percent_label" },
        {
          text: "Jumlah Transaksi",
          align: "center",
          value: "count",
        },
        { text: "Total Pembelian (Rp)", align: "end", value: "grand_total_sum" },
      ],

      headers_detail: [
        { text: "Nama Pelanggan", align: "left", value: "customer_name" },
        { text: "Jumlah Transaksi", align: "center", value: "count" },
        { text: "Diskon Outlet", align: "center", value: "discount_store_percent_label" },
        { text: "Total Pembelian (Rp)", align: "end", value: "grand_total_sum" },
      ],

      headers_detail_transaction: [
        { text: "Aksi", align: "center", width: 100, sortable: false, value: "aksi_table" },
        {
          text: "Tanggal",
          align: "center",
          value: "complete_datetime",
          width: 200,
          sortable: true,
        },
        {
          text: "Nomor Pembelian",
          align: "center",
          value: "number",
          sortable: true,
          width: 200,
        },
        {
          text: "Nomor Transaksi",
          align: "left",
          value: "transaction_number",
          sortable: true,
          width: 200,
        },
        {
          text: "Metode Pembayaran",
          align: "left",
          value: "payment_method",
          sortable: true,
          width: 170,
        },
        {
          text: "Tipe Penjualan",
          align: "left",
          value: "type_title",
          sortable: true,
          width: 140,
        },
        {
          text: "Total Item",
          align: "center",
          value: "total_product_item",
          sortable: true,
          width: 120,
        },
        {
          text: "Total Harga Jual (Rp)",
          align: "end",
          value: "total_product_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga Beli (Rp)",
          align: "end",
          value: "total_product_purchase_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Bruto (Rp)",
          align: "end",
          value: "total_bruto_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Diskon (Rp)",
          align: "end",
          value: "total_disount",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga Sesudah Diskon (Rp)",
          align: "end",
          value: "total_product_price_after_discount",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Biaya Servis (Rp)",
          align: "end",
          value: "total_charge_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total PPN (Rp)",
          align: "end",
          value: "total_tax_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Biaya Tambahan (Rp)",
          align: "end",
          value: "other_cost",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Pembulatan Nominal (Rp)",
          align: "end",
          value: "added_round_nominal_grand_total",
          sortable: true,
          width: 220,
        },
        {
          text: "Total Yang Harus di Bayar (Rp)",
          align: "end",
          value: "grand_total",
          sortable: true,
          width: 220,
        },
        {
          text: "Total Netto (Rp)",
          align: "end",
          value: "total_nett_price",
          sortable: true,
          width: 200,
        },
      ],

      headersDetail: [
        {
          text: "Gambar",
          align: "left",
          value: "product_image_url",
          width: 100,
        },
        {
          text: "Kode Produk",
          align: "center",
          value: "product_code",
          sortable: true,
          width: 100,
        },
        {
          text: "Nama Produk",
          align: "left",
          value: "product_name",
          sortable: true,
          width: 200,
        },
        {
          text: "Kategori Produk",
          align: "left",
          value: "category_name",
          sortable: true,
          width: 200,
        },
        {
          text: "Jumlah Kuantitas",
          align: "center",
          value: "product_qty",
          sortable: true,
          width: 100,
        },
        {
          text: "Harga Jual (Rp)",
          align: "end",
          value: "product_selling_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Beli (Rp)",
          align: "end",
          value: "product_purchase_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Diskon (Rp)",
          align: "end",
          value: "product_discount_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Markup (Rp)",
          align: "end",
          value: "product_markup_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga (Rp)",
          align: "end",
          value: "product_subtotal_price",
          sortable: true,
          width: 200,
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      filterstore: "",
      filternamestore: "",
      filtercustomercategory: "",
      filternamecustomercategory: "",
      filterdate: {},
      options: {},
      cardDetail: {},
      cardDetailInfo: {},
      cardDetailSummary: {},
      loading: false,
      hari: new Date().toISOString().substr(0, 10),

      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
        detail: false,
        detail_transaction: false,
        detailSalesResume: false
      },
      filterSave: {
        date: {},
        store: "",
        customercategory: "",
      },
      status: {
        showTimeRangePanel: false,
        date: false,
        store: false,
        customercategory: false,
      },
      detail: [],
      store: [],
      cashier: [],
      list: [],
      detail_transaction: [],
      customer_category: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesCustomer() {
      return [
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    // 'selected.pagination': function(val) {
    //   this.initialize(val+1)
    // },
    // options:{
    //   handler(){
    //     this.initialize()
    //   },
    //   deep:true
    // }
  },
  created() {
    // this.initialize()
    // this.fetchStore();
    this.fetchCustomerCategory();
  },
  methods: {
    /**
     * EVENTS
     */
    resetFilter() {
      this.$refs["base-table"].params.start_date &&
        delete this.$refs["base-table"].params.start_date;
      this.$refs["base-table"].params.end_date &&
        delete this.$refs["base-table"].params.end_date;
      this.$refs["base-table"].params.customer_category_id &&
        delete this.$refs["base-table"].params.customer_category_id;

      this.filterdate = [];
      this.status.date = false;

      this.status.customercategory = false;
      this.filternamecustomercategory = "";
      this.filtercustomercategory = "";

      this.$refs["base-table"].retrieveData();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.filterdate = {};
          this.status.date = false;
          this.$refs["base-table"].retrieveData();
          this.resetFilter()
          break;

        case "customer_category":
          this.filtercustomercategory = "";
          this.filternamecustomercategory = "";
          this.status.customercategory = false;
          this.resetFilter()
          this.$refs["base-table"].retrieveData();
          break;
      }

      if (this.status.date ||
            this.status.store ||
            this.status.customercategory) {
        
      } else {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}` })
        }
      }
    },

    responseDataStart() {
      this.displayBaseTable = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;
      /**
       * contains handler for filter params
       * date,list customer
       */

      //DATE
      let item = [];
      if (Object.keys(this.filterdate).length > 0) {
        //get each item

        //assign params to item
        this.$refs["base-table"].params.start_date = this.filterdate.start
        this.$refs["base-table"].params.end_date = this.filterdate.end
        //show chips
        this.status.date = true;
      }

      //LIST CUSTOMER
      if (this.filtercustomercategory) {
        this.$refs["base-table"].params.customer_category_id = this.filtercustomercategory;

        this.customer_category.forEach((el) => {
          this.status.customercategory = true;
          if (this.filtercustomercategory == el.id) {
            this.filternamecustomercategory = el.title;
          }
        });
      }
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;

      this.fetchSummaryData(this.selected_store_id)

      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      let start_date = this.filterdate.start ? this.filterdate.start : ''
      let end_date = this.filterdate.end ? this.filterdate.end: '' 
      let customer_category_id = this.filtercustomercategory

      const response  = await get(`franchise/report/sales-customer-category/summary`, {
        params: {
          store_id,
          start_date,
          end_date,
          customer_category_id
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseDataStartTransaction() {
      this.displayBaseTableDetailTransaction = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;
      /**
       * contains handler for filter params
       * date,list customer
       */

      //DATE
      let item = [];
      if (Object.keys(this.filterdate).length != 0) {
        //get each item
        
        //assign params to item
        this.$refs["base-table"].params["filter[0][type]"] = "date";
        this.$refs["base-table"].params["filter[0][field]"] = "input_complete_datetime";
        this.$refs["base-table"].params["filter[0][comparison]"] = "bet";
        this.$refs["base-table"].params["filter[0][value]"] = this.filterdate.start+"::"+this.filterdate.end;
        //show chips
        this.status.date = true;
        this.filterDate = item;
      }

      //LIST CUSTOMER
      if (this.filtercustomercategory) {
        this.$refs["base-table"].params.customer_category = this.filtercustomercategory;

        this.customer_category.forEach((el) => {
          this.status.customercategory = true;
          if (this.filtercustomercategory == el.customer_category_id) {
            this.filternamecustomercategory = el.customer_category_name;
          }
        });
      }
    },

    responseDataEndTransaction(res) {
      // console.log(res);
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTableDetailTransaction = false;
      } else {
        this.emptyData = false;
        this.displayBaseTableDetailTransaction = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },

    /**
     * END EVENTS
     */

    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },
    
    
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    fetchStore() {
      get(`franchise/store`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            let AllStore = { store_id: "0", store_name: "Semua Toko" };
            response.data.data.results.unshift(AllStore);
            this.store = response.data.data.results;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchCustomerCategory() {
      this.process.run = true;
      get(`franchise/store/customer_category`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.process.run = false;
            this.customer_category = res.results.data;
          }
          this.process.run = false;
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    toDetail(item) {
      this.customer_category_category_name = item.customer_category_title
      get(`franchise/report/sales-customer-category/customer`, {
        params: {
          customer_category_id: item.customer_category_id,
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.dialog.detail = true;
            this.detail = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    toDetailTransaction(item){
      this.isLoadAgains = true;
      this.customer_name = item.customer_name
      this.customer_id = item.customer_id
      let date1 = ""
      let date2 = ""
      if (this.filterdate.length != 0) {
        date1 = moment(this.filterdate[0]).format("YYYY-MM-DD")
        date2 = moment(this.filterdate[1]).format("YYYY-MM-DD")
      }
      this.dialog.detail_transaction = true;
      setTimeout(() => {
        this.isLoadAgains = false;
      }, 2000)
    },
    async toDetailSalesOrder(item) {
      this.dialog.detailSalesResume = true;
      this.transaction_number = item.transaction_number;
      // this.cardDetail = item.sales_resume_detail;
      // this.cardDetailSummary = item;
      await get(`franchise/store/transaction/detail`, {
        params : {
          store_id: this.selected_store_id,
          id: item.id 
        }
      }).then(response => {
        let res = response.data;
        if (res.status == 200) {
          this.cardDetail = res.results.data.product_list;
          this.cardDetailInfo = res.results.data.transaction_info_object;
          this.cardDetailSummary = res.results.data.summary_object;
        } else {
          this.cardDetail = [];
          this.cardDetailSummary = [];
        }
      })
    },
    
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
